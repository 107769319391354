import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './styles.css';
import './assents/css/fonts.css';
import './assents/css/icomoon.css';
import './assents/css/animate.css';

import Main from './pages/Main';
import Projeto from './pages/Projeto';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/agenda" element={<Projeto />} />
      </Routes>
    </Router>
  );
}

export default App;