import React, { useEffect } from 'react';

import '../../styles.css';

function Projeto() {
  useEffect(() => {
    // Redireciona o usuário para um link externo
    window.location.href = 'https://calendar.app.google/ZmvgrkmXkshh3PoC6';
  }, []);

  return (
    <div className='redirecionando'>
      <p>Redirecionando para agenda...</p>
      
    </div>
  );
}

export default Projeto;
